<template>
  <div>
    <div class="main-view" :class="{ hidden: scrolledDown }">
      <div class="sider sidebar">
        <div class="holder">
          <div class="xx" role="navigation" aria-labelledby="main-nav-heading">
            <div class="title" id="main-nav-heading">Contents</div>
            
            <a class="listit" href="#Project">Projects</a>

            <a class="listit" href="#Research">Research</a>

            <a class="listit" href="#Bio">Bio</a>

            <a class="listit" href="#Contact">Extra Information</a>

            <a class="listit" href="#Contact1">Contact</a>
            <br />
            <div class="contact">

              <a id="copyEmail" @click="copyEmail">Email</a>
              <a id="copyPhone" @click="copyPhone">Phone</a>
              <a href="https://cal.com/nicholas-sabry/30min" target="_blank"> Book Meeting</a>

              <a></a>
              <a></a>
              <!--
              <a href='https://docwebsitehosting.netlify.app/pdf/Resume.pdf' target="_blank">PDF Resume</a>

              <a href='https://docwebsitehosting.netlify.app/pdf/Academic%20Curriculum%20Vitae.pdf' target="_blank">PDF Curriculum Vitae</a>
              -->
              <a href="https://drive.google.com/file/d/1T5q5BMrjqCnMz0mhPWYR-Q88w3G0euek/view?usp=sharing" target="_blank">Transcript</a>

              <a></a>
              <a></a>

              <a href="https://www.linkedin.com/in/nicholas-sabry/" target="_blank">LinkedIn</a>

              <a href="https://github.com/leonthelionman" target="_blank">GitHub</a>

              <a href="https://www.youtube.com/@nicksabry/videos" target="_blank">YouTube</a>
              
              <br />
            </div>
          </div>
        </div>
      </div>
      <div class="main">
        <section id="intro">
          <div class="txt">
            <h1>
              <span class="intro-title">Hi, I'm Nick</span>
              <br />
              <span class="intro-subtitle">this is my project portfolio</span>
            </h1>

            <!-- Add this div for the description text box -->
            <div class="intro-description">
              I'm a mechanical engineer with hands-on experience in the automotive industry, driven to work with leading companies around the world to push innovation in materials, process optimization, and product excellence.
            </div>

            <div class="scroll-down-wrapper">
              <div class="scroll-text">(scroll down)</div>
              <div class="blinking-arrow">↓</div>
            </div>

          </div>
        </section>
        
        <section id="Project" aria-labelledby="project-heading">
          <h2 id="project-heading">Projects</h2>
          <div class="cards">
            <div class="card-col">
              <!-- Left Side -->
              <Card :item="projects[10]" :txt="select" /> <!-- NEMAK -->
              <Card :item="projects[5]" :txt="select" /> <!-- Casting Project -->
              <!--<Card :item="projects[1]" :txt="select" /> Text Clustering -->
              <!--<Card :item="projects[0]" :txt="select" />  Sweden-->
              <!--<Card :item="projects[6]" :txt="select" /> MicroFab-->
              <Card :item="projects[3]" :txt="select" />  <!-- Hover Craft -->
              <Card :item="projects[7]" :txt="select" />  <!-- Train -->
            </div>
            <div class="card-col">
              <!-- Right Side -->
              <Card :item="projects[4]" :txt="select" />  <!-- Capstone -->
              <Card :item="projects[2]" :txt="select" />  <!-- Motor -->
              <Card :item="projects[8]" :txt="select" /> <!-- Office-->
            </div>
          </div>
        </section>

        <section id="Research" aria-labelledby="research-heading">
        <h2 id="research-heading">Research</h2>
          <div class="cards">
            <div class="card-col">
              <!-- Left Side -->
              <Card :item="research[0]" @click.native="openPdf(research[0].source)" />
              <Card :item="research[2]" @click.native="openPdf(research[2].source)" />
            </div>
            <div class="card-col">
              <!-- Right Side -->
              <Card :item="research[1]" @click.native="openPdf(research[1].source)" />
            </div>
          </div>
        </section>


        <section id="Bio" aria-labelledby="intro-heading" class="bio-section">
          <h2 id="intro-heading" class="bio-title">Bio</h2>
          <div class="bio-content">

          <p>I am a resourceful mechanical engineer with comprehensive experience in mechanical design, materials science, and manufacturing. My expertise spans industry partnerships, hands-on fabrication, and academic research from both my bachelor’s and Ph.D. programs, allowing me to identify and solve complex engineering challenges with a balance of technical knowledge and practical skills.</p>

          <p>I am a process improvement specialist with a strong background in working with Tier I automotive manufacturers. My work includes an in-depth analysis of aluminum battery trays to enhance safety and reduce costs, utilizing nuclear technology for internal structure scanning through neutron diffraction. This method allowed me to develop cost-effective straightening processes for the Jeep 4xE Rubicon battery tray in partnership with industry experts. My ability to navigate and resolve complex, underdefined challenges led to my direct acceleration into a Ph.D., where I refined my research skills, problem-solving capabilities, and collaborative approach.</p>

          <p>I also bring a wide range of hands-on experience in machining, prototyping, and fabrication, with proficiency in MIG/TIG welding, CAD design, and CNC. I’ve completed projects ranging from designing high-precision casting setups to building an Arduino-controlled hovercraft and custom drone. These experiences emphasize my ability to bridge engineering theory with real-world applications and implementation.</p>
              <div class="connect-section">
                <a class="listit project-link" href="#Project">Projects Section</a>
              </div>
          </div>
        </section>


<!-- 
          <div class="Education">
            <h3>Education</h3>
            <div class="cont" v-for="i in school" :key="i.title">
              <span>{{ i.date }}</span>
              <span>{{ i.extra }}</span>
              <span>{{ i.title }}</span>
              <span>{{ i.location }}</span>
              <span>{{ i.txt }}</span>
            </div>
          </div>
          <br />
-->
        <section id="Contact" aria-labelledby="Contact-heading">
          <h2 id="Contact-heading">Extra Information</h2>
          <!-- 
          <br />
          <a href='https://docwebsitehosting.netlify.app/pdf/Resume.pdf' target="_blank">PDF Resume</a>
          <br />

          <br />
          <a href='https://docwebsitehosting.netlify.app/pdf/Academic%20Curriculum%20Vitae.pdf' target="_blank">PDF Curriculum Vitae</a>
          <br />
        -->
          <br />
          <a href="https://drive.google.com/file/d/1T5q5BMrjqCnMz0mhPWYR-Q88w3G0euek/view?usp=sharing" target="_blank">Transcript</a>
          <br />

          <br />
          <a href="https://www.linkedin.com/in/nicholas-sabry/" target="_blank">LinkedIn</a>
          <br />

          <br />
          <a href="https://github.com/leonthelionman" target="_blank">GitHub</a>
          <br />

          <br />
          <a href="https://www.youtube.com/@nicksabry/videos" target="_blank">YouTube</a>
          <br />
        </section>
        
        <section id="Contact1" aria-labelledby="Contact-heading">
          <h2 id="Contact-heading">Contact</h2>
          
          <br />
            nicholas@sabry-engineering.com
          <br />
            <a href="mailto:nicholas@sabry-engineering.com">
              <Fa fa="envelope-o" /> Write e-mail
            </a>
          <br />

          <br />
          (778) 581-6075
          <br />
            <a href="tel:(778) 581-6075"> 
            <Fa fa="phone" /> Call</a>
          <br />

          <br />
          Book Meeting
          <br />
          <a href="https://cal.com/nicholas-sabry/30min" target="_blank"> 
            <Fa fa="calendar" /> Calendar</a>
          <br />

        </section>
       
      </div>
    </div>
    <PopOver :selected="selected" :closeWindow="closePopOver" />
  </div>
</template>

<script>
import Card from './globals/Card.vue';
import Fa from './globals/Fa.vue';
import PopOver from './PopOver.vue';
import VueClipboard from 'vue-clipboard2';



export default {
  metaInfo: {
    title: 'Nicholas Sabry - Mechanical Engineer Portfolio',
    meta: [
      {
        name: 'description',
        content: 'This website serves as a general portfolio for my mechanical engineering work and research. Discover the projects and research of Nicholas Sabry, a dedicated mechanical engineer.'
      },
      {
        name: 'keywords',
        content: 'Nicholas Sabry, mechanical engineering, engineering portfolio, mechanical engineer projects, engineering research'
      }
    ]
  },
  components: {
    Card,
    Fa,
    PopOver,
    VueClipboard,
  },
  data: () => ({
    Experience: [
        { 
        date: 'September / 2020 – July / 2024',
        extra: '',
        title: 'Engineering and Materials Researcher',
        location: 'The University of British Columbia, Canada',
        txt: "The battery tray is an essential component that protects and controls battery-cell temperatures in electric and plug-in hybrid vehicles.  The functional stress limit of the battery tray heavily depends on the residual stress acquired from the manufacturing process. Consequently, exceeding the stress limit of the battery tray during operation could compromise the battery cells and may risk the vehicle's safety. Hence, understanding residual stress formation is vital for design and safety concerns. In the current study, Al 6061-plates are friction-stir-welded (FSW) to an A365 high-pressure die-cast battery tray to create a sealed coolant channel with a high cooling capacity to maximize battery cell efficiency and prevent damage due to unregulated temperature. Consequently, this multi-material FSW lap weld introduces residual stress into the battery tray, leading to distortion in the tray. Burnishing and coining are post-FSW straightening techniques that correct the distortion; however, straightening the battery tray has unknown effects on the residual stress. Therefore, neutron diffraction is utilized to characterize residual stresses after straightening.",
      },
        { 
        date: 'September / 2020 – December / 2021',
        extra: '',
        title: 'Graduate Teaching Assistantships',
        location: 'The University of British Columbia, Canada',
        txt: "The responsibilities of this position included teaching undergraduate groups of 30-45 students on topics related to the machining process essential to the production of any engineered product. The topics and equations taught included casting, forming, welding, meteorology, and all the aspects of traditional machining. Further responsibilities include assisting with the student's evaluation, motivating students to enhance their learning experience, and answering any questions from the students.",
      },
      { 
        date: 'September / 2019 – June / 2020',
        extra: '',
        title: 'Production Enhancement Internship',
        location: 'Tolko Industries, Canada',
        txt: 'Tolko Industries Ltd. Lake Country Division is a forest product manufacturing company. They specifically make fruit harvest bins used for the local agriculture in the Okanagan and other parts of Western Canada and the USA. Unfortunately, Tolko currently is not producing enough bins to generate adequate company revenue.This project designed manufacturing processes and production layouts for equipment installation, assembly, machining, and material handling, focusing on improved production efficiency. The redesigned layouts utilized 5S+1 systems, preventive maintenance schedules, and PLC upgrades to increase bin production efficiency.',
      },
      {
        date: 'May / 2019 – July / 2019',
        extra: '',
        title: 'Fitness-for-Service Characterization Internship',
        location: 'Lund University, Sweden',
        txt: "This internship focused on the fitness-for-service characterization of novel aluminum alloys for applications in next-generation IC engines utilizing additions of rare earth elements, such as Cerium (Ce), to Al alloys. The alloy's mechanical properties were characterized by tension, torsion, and fatigue loading. While the microstructure and resulting fracture surface were analyzed through an optical microscope and embedded analysis systems to quantify differences in brittle and ductile modes of fracture.",
      },
      {
        date: 'September / 2017 – January / 2018',
        extra: '',
        title: 'Battery Innovation Project Assistant',
        location: 'The University of British Columbia, Canada',
        txt: "Cycling Panasonic GA and Samsung 30Q battery cells to characterize performance loss for Grin Technology. Analyzing data, developing charging technology, and helping build testing protocols.",
      },
    ],
    school: [
      {
        date: 'August 2024',
        extra: '',
        title: "Doctor of Philosophy - Mechanical Engineering",
        location: 'The University of British Columbia, Canada',
        txt: '',
      },
      {
        date: 'May 2020',
        extra: '',
        title: "Bachelor's degree - Mechanical Engineering",
        location: 'The University of British Columbia, Canada',
        txt: '',
      },
    ],
    projects: [
      {
        id: 0,
        images: [
          {
            img: '/images/sweden.gif',
          },
          {
            img: '/images/06.png',
          },
        ],
        title: 'Aluminum Alloys for IC Engines',
        tags: ['Aluminum Alloys for IC Engines'],
        demo: '',
        source: 'https://drive.google.com/file/d/1Ox8wlup8MmAspwzCUeQ9U9rgvHzStbDu/view?usp=sharing',
      },
      {
        id: 1,
        images: [
          {
            img: '/images/text-clustering.gif',
          },
          {
            img: '/images/01.png',
          },
        ],
        title: 'Text Clustering Algorithm',
        tags: ['Text Clustering Algorithm'],
        demo: '',
        source: 'https://drive.google.com/file/d/19ekfrIs3Vaaxkc7Rw1yru82nFmACA7a4/view?usp=sharing',
      },
      {
        id: 2,
        images: [
          {
            img: '/images/motor.gif',
          },
          {
            img: '/images/03.png',
          },
        ],
        title: '3 Phase Motor',
        tags: ['3 Phase Motor'],
        demo: '',
        source: 'https://www.youtube.com/watch?v=KMNwfbTEBX0&list=PLy4XSJjDY25OjEj1yoCKhDZuhf_TmH_6w&index=5',
      },
      {
        id: 3,
        images: [
          {
            img: '/images/hovercraft.gif',
          },
          {
            img: '/images/02.png',
          },
        ],
        title: 'Hovercraft',
        tags: ['Hovercraft',],
        demo: '',
        source: 'https://www.youtube.com/watch?v=c19TbN9TUh0&list=PLy4XSJjDY25OjEj1yoCKhDZuhf_TmH_6w&index=3',
      },
      {
        id: 4,
        images: [
          {
            img: '/images/capstone.gif',
          },
          {
            img: '/images/04.png',
          },
        ],
        title: 'Production Enhancement',
        tags: ['Production Enhancement'],
        demo: '',
        source: 'https://www.youtube.com/watch?v=1etsTHkG9bg',
      },
      {
        id: 5,
        images: [
          {
            img: '/images/cast3.gif',
          },
          {
            img: '/images/05.png',
          },
        ],
        title: 'Plate Casting',
        tags: ['Plate Casting'],
        demo: '',
        source: 'https://hppm.ok.ubc.ca/research/residual-stress-characterization/#:~:text=Residual%20stress%20in%20friction%20stir%20welding',
      },
      {
        id: 6,
        images: [
          {
            img: '/images/MicroFab.gif',
          },
          {
            img: '/images/07.png',
          },
        ],
        title: 'Micro Fabrication',
        tags: ['Micro Fabrication'],
        demo: '',
        source: 'https://www.youtube.com/watch?v=Okvxo_dPgEc&feature=youtu.be',
      },
      {
        id: 7,
        images: [
          {
            img: '/images/Train.gif',
          },
          {
            img: '/images/08.png',
          },
        ],
        title: 'Manfucatred Train',
        tags: ['Manfucatred Train'],
        demo: '',
        source: 'https://docwebsitehosting.netlify.app/pdf/Machined%20Train.pdf',
      },
      {
        id: 8,
        images: [
          {
            img: '/images/office1.gif',
          },
          {
            img: '/images/09.png',
          },
        ],
        title: 'Office Container',
        tags: ['Office Container'],
        demo: '',
        source: 'https://www.youtube.com/watch?v=ZghKnVWzoo0',
      },
      {
        id: 9,
        images: [
          {
            img: '/images/office1.gif',
          },
          {
            img: '/images/09.png',
          },
        ],
        title: 'Office Container',
        tags: ['Office Container'],
        demo: '',
        source: 'https://www.youtube.com/watch?v=ZghKnVWzoo0',
      },
      {
        id: 10,
        images: [
          {
            img: '/images/Nemak-2.gif',
          },
          {
            img: '/images/10-1.png',
          },
        ],
        title: 'Battery Tray',
        tags: ['Battery Tray'],
        demo: '',
        source: 'https://docwebsitehosting.netlify.app/pdf/Paper_2.pdf',
      },
      {
        id: 11,
        images: [
          {
            img: '/images/FSWResearch.gif',
          },
          {
            img: '/images/10-1.png',
          },
        ],
        title: 'FSW Sim & Exp',
        tags: ['FSW Sim & Exp'],
        demo: '',
        source: 'https://docwebsitehosting.netlify.app/pdf/Paper_2.pdf', // ADD THIS AFTER PAPER PUBLICATION 
      },  
    ],
    research: [
      {
        id: 0,
        images: [
          {
            img: '/images/P1_F1_1.jpg',
          },
          {
            img: '/images/Paper_1_Front.png',
          },
        ],
        //title: 'Characterization of microstructure and residual stress following thefriction stir welding of dissimilar aluminum alloys',
        //tags: ['Characterization of microstructure and residual stress following thefriction stir welding of dissimilar aluminum alloys'],
        title: 'Publication',
        tags: ['Publication'],
        demo: '',
        source: 'https://docwebsitehosting.netlify.app/pdf/Paper_1.pdf',
      },
      {
        id: 1,
        images: [
          {
            img: '/images/P2_F1_2.jpg',
          },
          {
            img: '/images/Paper_2_Front.png',
          },
        ],
        //title: 'Stress characterization for friction-stir-welded electric vehicle battery trays with application of neutron diffraction',
        //tags: ['Stress characterization for friction-stir-welded electric vehicle battery trays with application of neutron diffraction'],
        title: 'Publication',
        tags: ['Publication'],
        demo: '',
        source: 'https://docwebsitehosting.netlify.app/pdf/Paper_2.pdf',
      },
      {
        id: 2,
        images: [
          {
            img: '/images/D1_3.png',
          },
          {
            img: '/images/Dissertation_Front.png',
          },
        ],
        title: 'Ph.D Dissertation',
        tags: ['Ph.D Dissertation'],
        demo: '',
        source: 'https://dx.doi.org/10.14288/1.0444948', // https://docwebsitehosting.netlify.app/pdf/ubc_2024_september_sabry_nicholas.pdf
      },
    ],
    selected: null,
      // Define the data properties for email and phoneNumber
      email: 'nicholas@sabry-engineering.com',
      phoneNumber: '(778) 581-6075',
  }),
  mounted() {
  this.showWelcomeAlert(); // Show the SweetAlert on page load
  window.onscroll = this.lodash.debounce(this.scrollcalc, 5);
  this.scrollcalc();

  // Solution 1: Smooth scrolling for internal anchor links
  let anchorlinks = document.querySelectorAll('a[href^="#"]');
  for (let item of anchorlinks) {
    item.addEventListener('click', (e) => {
      e.preventDefault(); // Prevent default anchor click behavior
      let hashval = item.getAttribute('href');
      let target = document.querySelector(hashval);
      if (target) {
        target.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
        });
        history.pushState(null, null, hashval);
      }
    });
  }

  // Solution 2: Scroll to the section if there's a hash in the URL on page load
  this.scrollToHash();
},

methods: {
  scrollToHash() {
    // Check if there is a hash in the URL
    let hash = window.location.hash;
    if (hash) {
      // Wait for the page to finish rendering before scrolling
      this.$nextTick(() => {
        let target = document.querySelector(hash);
        if (target) {
          target.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
          });
        }
      });
    }
  },





  select: function (id) {
    this.selected = this.projects.find((x) => x.id === id);
  },
  selectresearch: function (id) {
    this.selected = this.research.find((x) => x.id === id);
  },
  openPdf: function (url) {
    window.open(url, '_blank'); // Open in new tab
  },
  closePopOver() {
    this.selected = null;
  },
  scrollcalc() {
    let mainNavLinks = document.querySelectorAll('.xx a');
    let fromTop = document.documentElement.scrollTop;
    let navbarHeight = 200;

    mainNavLinks.forEach((link) => {
      if (link.hash == '') return;

      let section = document.querySelector(link.hash);
      if (section == null) return;
      if (
        section.offsetTop <= fromTop + window.innerHeight &&
        section.offsetTop + section.offsetHeight > fromTop + navbarHeight
      ) {
        link.classList.add('current');
        let allCurrents = document.querySelectorAll('.current');
        let allFirsts = document.querySelector('.first');
        if (allFirsts != null) {
          allFirsts.classList.remove('first');
        }
        allCurrents[0].classList.add('first');
      } else {
        link.classList.remove('current');
      }
    });
  },
  copyToClipboard(text) {
      const tempTextArea = document.createElement("textarea");
      tempTextArea.value = text;
      document.body.appendChild(tempTextArea);
      tempTextArea.select();
      document.execCommand("copy");
      document.body.removeChild(tempTextArea);
    },



    copyEmail() {
  const emailToCopy = "nicholas@sabry-engineering.com";
  this.copyToClipboard(emailToCopy);
  this.$swal.fire({
    position: 'top-left',
    icon: 'success',
    title: emailToCopy + "<br>"+ "<br>Email Copied!",
    showConfirmButton: false,
    timer: 1100,
    didOpen: () => {
      // Calculate the width of the scrollbar
      const scrollbarWidth = window.innerWidth - document.documentElement.clientWidth;

      // Modal is open, allow scrolling on background
      document.body.style.overflow = 'auto';

      // Compensate for the scrollbar width to prevent shifting
      document.body.style.paddingRight = scrollbarWidth + 'px';
    },
    didClose: () => {
      // Modal is closed, reset the overflow property
      document.body.style.overflow = '';
    }
  });





},
    copyPhone() {
      const phoneToCopy = "(778) 581-6075";
      this.copyToClipboard(phoneToCopy);
      this.$swal.fire({
        position: 'top-left',
        icon: 'success',
        title: phoneToCopy + "<br>"+ "<br>Phone # Copied!", // New line added here
        showConfirmButton: false,
        timer: 1100,
        
    didOpen: () => {
      // Calculate the width of the scrollbar
      const scrollbarWidth = window.innerWidth - document.documentElement.clientWidth;

      // Modal is open, allow scrolling on background
      document.body.style.overflow = 'auto';

      // Compensate for the scrollbar width to prevent shifting
      document.body.style.paddingRight = scrollbarWidth + 'px';
    },
    didClose: () => {
      // Modal is closed, reset the overflow property
      document.body.style.overflow = '';
    }

   });
 },


},
  props: {
    scrolledDown: Boolean,
  },
};
</script>

<style>

.sider.sidebar {
  margin-top: -80px; /* Adjust this value as needed */
}

#intro .txt h1 {
  margin-top: -142px; /* Adjust this value as needed */
}


/* bio */
.bio-section {
  padding: 3rem 1.5rem;
  background-color: transparent; /* Keeps background aligned with default website color */
  text-align: center;
}

.bio-title {
  font-size: 2rem;
  color: var(--txt-med);
  margin-bottom: 1.5rem;
}

.bio-content {
  max-width: 800px;
  margin: 0 auto;
  background-color: rgba(255, 255, 255, 0.1); /* Light grey/white overlay */
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  color: var(--txt);
  text-align: justify; /* Justifies the text */
}

.bio-content p {
  font-size: 1rem;
  line-height: 1.6;
  margin-bottom: 1rem;
  color: var(--txt-light); /* Light text color for readability */
}

.bio-content .connect-section {
  text-align: center;
  margin-top: 1.5rem;
}

.project-link {
  display: inline-block;
  padding: 0.75rem 2rem;
  border-radius: 4px;
  background-color: #883b3b;
  color: #fff;
  text-decoration: none;
  font-weight: bold;
  margin-top: 1rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
}

.project-link:hover {
  background-color: #0056b3;
}

.connect-section {
  display: flex;
  justify-content: center; /* Centers the button horizontally */
  align-items: center;
}

/* Adjusting the wrapper to be further down and centered */
.scroll-down-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 50px; /* Increase the margin to move it further from the text */
  position: relative; /* Allow control over exact position */
  top: 60px; /* Move it further down the page */
}

.scroll-text {
  font-size: 14px; /* Small font size for "scroll down" */
  margin-bottom: 1px; /* Space between "scroll down" and the emoji */
  color: hsl(0deg 0% 63%);
}

/* Add styles for the blinking arrow */
.blinking-arrow {
  font-size: 50px; /* Size of the emoji */
  animation: blink 1s infinite; /* Blink animation */
}

/* Keyframes for the blinking effect */
@keyframes blink {
  0%, 100% { opacity: 1; }
  50% { opacity: 0; }
}


.intro-description {
  max-width: 500px; /* Limits width */
  margin: 20px 0 0; /* Aligns it to the top */
  padding: 15px 20px;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 8px;
  color: #d3d3d3;
  font-size: 1rem;
  text-align: center;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);

  /* Align left with title */
  display: inline-block;
  align-self: flex-start; /* Aligns it to the left within a flex container */
}







#intro .txt h1 {
  font-size: 40px; /* Base font size for the h1 */
  line-height: 1.2; /* Controls general spacing between lines */
}

#intro .txt h1 .intro-title {
  font-size: 40px; /* Keep the size larger for "Hi, I'm Nick" */
  font-weight: bold; /* You can style this part however you like */
}

#intro .txt h1 .intro-subtitle {
  font-size: 38px; /* Set a smaller size for "this is my project portfolio" */
  font-weight: bold; /* You can style this part however you like */
  display: block;
  margin-top: 10px; /* Add some space between the two lines */
}





.swal2-icon.swal2-success {
  border-color: red !important;
}

.swal2-icon.swal2-success .swal2-success-ring {
  border-color: red !important;
}

.swal2-icon.swal2-success .swal2-success-line-tip,
.swal2-icon.swal2-success .swal2-success-line-long {
  background-color: red !important;
  border-color: red !important;
}

/* Make the SweetAlert2 container transparent */
.swal2-container {
  background-color: transparent !important;
}

/* Optional: If you want to make the popup box transparent as well */
.swal2-popup {
    background-color: #1D1E21 !important; /* Your chosen background color */
    box-shadow: none !important; /* Remove shadow if desired */
    border-radius: 50px !important; /* Large border-radius for tablet shape */
    width: 230px !important; /* Set a fixed width */
    height: 230px !important; /* Set a fixed height */
    /*display: flex !important;/*  */
    /*align-items: center !important;/*  */
    /*justify-content: center !important;/*  */
  }

/* Change the title color to white */
.swal2-title {
  color: white !important;
  font-family: 'Your Font Family', sans-serif !important; /* Replace with your actual font family */
  font-size: 1rem !important;
}

ul {
    list-style-type: disc;
    padding-left: 20px;
  }
  li {
    margin-bottom: 10px; /* Adjust the value as needed */
  }
  li a {
    color: #007BFF;
  }

/* Hide scrollbar for Chrome, Safari and Opera but allow scrolling */
html {
    scrollbar-width: thin;
    scrollbar-color: transparent transparent;
}

body::-webkit-scrollbar {
    width: 0.1px;
    background: transparent; /* Optional: just to make sure it's transparent */
}

body {
    -ms-overflow-style: none;  /* IE and Edge */
}


</style>

<style lang="scss" scoped>
#intro {
  .txt {
    a {
      display: none;
      text-decoration: none;
      color: white;
    }
  }
}
@media screen and (max-width: 680px) {
  .cards {
    display: block !important;
  }
  .card {
    width: 100% !important;
    height: 33rem !important;
    
  }
  #intro {
    .txt {
      a {
        display: hidden;
      }
    }
  }
  .hidden {
    #intro {
      .txt {
        a {
          display: block;
        }
      }
    }
  }
  .cont {
    grid-template-areas:
      'date'
      'extra'
      'title'
      'location'
      'txt' !important;
    grid-template-columns: 100% !important;
  }
  .txt h1 {
    font-size: 7vw !important;
  }
}
#Contact,
#CV {
  a {
    color: white;
    i {
      font-size: 0.9rem;
    }
  }
  a:hover {
    color: hsl(0deg 0% 63%);
  }
}
#Contact1,
#CV {
  a {
    color: white;
    i {
      font-size: 0.9rem;
    }
  }
  a:hover {
    color: hsl(0deg 0% 63%);
  }
}
.Bio,
.CV {
  h3 {
    font-size: 0.9rem;
  }
  cursor: default;
}
.cont {
  display: grid;
  grid-template-areas:
    'date title'
    'extra location'
    '. txt';
  grid-template-columns: 11rem 370px;
  gap: 0rem 1rem;
  margin-bottom: 1.3rem;
  span:nth-child(1) {
    grid-area: date;
    font-weight: 600;
    font-size: 0.9rem;
    color: hsl(0deg 0% 63%);
    letter-spacing: -0.02rem;
  }
  span:nth-child(2) {
    grid-area: extra;
    font-size: 0.87rem;
    color: hsl(0deg 0% 63%);
    letter-spacing: -0.02rem;
  }
  span:nth-child(3) {
    grid-area: title;
  }
  span:nth-child(4) {
    grid-area: location;
    font-size: 0.9rem;
    color: hsl(0deg 0% 63%);
  }
  span:nth-child(5) {
    grid-area: txt;
    font-size: 0.9rem;
    color: hsl(0deg 0% 63%);
  }
}

.contact {
  // margin: 2rem 0rem;
  display: flex;
  flex-flow: column;
  gap: 0.7rem;
  width: 100%;
  transition: opacity 0.3s;
  a {
    text-decoration: none;
    color: var(--txt-med);
    padding: 0rem 1.5rem;
  }
}
.contact {
  a:hover {
    color: white;
  }
}
#Intro {
  a {
    color: var(--accent-1);
    text-decoration: none;
    i {
      margin-left: 0.4rem;
    }
  }
}
section {
  padding: 6rem 0rem;
  &:empty {
    display: none;
  }
  span {
    display: block;
    max-width: 550px;
  }
}
.mainitem-active {
  border-radius: 6px;
  background: hsl(220 100% 71% / 0.11);
  color: hsl(220 100% 53% / 1) !important;
  margin-bottom: 0.3rem;
}
.title {
  font-size: 12px !important;
  font-weight: 700 !important;
  margin: 0.2em 0em 1rem 0rem;
  text-transform: capitalize;
  color: #b2b2b2;
  text-transform: uppercase;
  letter-spacing: 0.02rem;
  width: 100%;
  text-align: center;
}
.xx {
  display: flex;
  flex-flow: column;
  align-items: self-end;
  gap: 0.7rem;
  a {
    transition: min-width 0.3s, border 0.3s, padding 0.3s, box-shadow 0.3s,
      background 0.15s;
    width: fit-content;
    min-width: 210px;
    max-width: -webkit-fill-available;
  }
}
// This is where we change the colors for the down bar // This is where we change the colors for the down bar // This is where we change the colors for the down bar
.listit:nth-child(2).first {
  background: rgb(220, 20, 60);
}
.listit:nth-child(3).first {
  background: rgb(255, 49, 49);
}
.listit:nth-child(4).first {
  background: rgb(255, 36, 0);
}
.listit:nth-child(5).first {
  background: rgb(128, 0, 32);
}
.listit:nth-child(6).first {
  background: rgb(170, 74, 68);
}
.listit:nth-child(7).first {
  background: rgb(128, 0, 32);
}
.listit:hover {
  background-color: hsla(0, 0%, 80%, 0.15);
}
.listit {
  
  text-decoration: none;
  display: block;
  padding: 0.75rem 1.8rem;
  margin-right: 1rem;
  color: white;
  font-size: 0.95em;
  cursor: pointer;
  transition: border 0.3s, padding 0.3s, box-shadow 0.3s, background 0.15s;
  border-left: 2px solid transparent;
  font-weight: 500;
  border-radius: 0.4rem;
  float: left;
}
.mainitem {
  padding: 0.5rem 0.5rem;
}
.current {
  border-left: 6px solid #ffffff1c;
  // box-shadow: 0px 0px 0px 0px black;
}
.first {
  color: black;
  color: black !important;
  padding-left: 2rem;
  font-weight: 600;
  transform-origin: left;
  animation: pop 0.25s ease-out;
}
@keyframes pop {
  35% {
    transform: scale(1.05);
  }
  75% {
    transform: scale(0.96);
  }
  100% {
    transform: scale(1);
  }
}
</style>

<style lang="scss" scoped>
.card-col:nth-child(2) {
  margin-top: 5rem;
}
.cards {
  display: flex;
  gap: 1.7rem;
}
.card-col {
  display: flex;
  flex-flow: column;
  gap: 1.7rem;
}

.sidebar,
.sideoptions {
  transition: width 0.3s;
}
@media screen and (max-width: 1300px) {
  .main-view {
    grid-template: 'siderbar main main';
  }
  .sideoptions {
    display: none;
  }
}
@media screen and (max-width: 1023px) {
  .main-view {
    grid-template: 'main main main' !important;
    grid-template-columns: 1fr !important;
    width: auto !important;
  }
  .sidebar {
    display: none;
  }
}

.holder {
  scrollbar-color: #bebebe transparent;
  scrollbar-width: thin;
}
.holder:hover {
  scrollbar-color: #bebebe #f2f2f2;

  scrollbar-width: revert !important;
}

.holder::-webkit-scrollbar {
  width: 15px;
  padding: 10px;
}

.holder::-webkit-scrollbar-thumb {
  border-radius: 10px;
  border: 5px solid transparent;
  background-clip: content-box;
}

.sider:hover .holder::-webkit-scrollbar {
  width: 15px;
}

.sider:hover .holder::-webkit-scrollbar-track {
  border-radius: 10px;
}

.sider:hover .holder::-webkit-scrollbar-thumb {
  border: 0;
  border-radius: 10px;
}

.sider:hover .holder::-webkit-scrollbar-thumb:hover {
  background: #818b99;
}
.sider {
  height: 100%;
  width: 310px;
}
.holder {
  padding: 1rem;
  position: -webkit-sticky;
  position: sticky;
  top: 60px;
  height: calc(100vh - 90px);
  overflow-y: auto;
}
.sidebar {
  justify-self: right;
}

.link .fas {
  font-size: 0.8rem;
}

.filters > .text {
  padding: 0.5em 0em;
  display: block;
}
.filters > .text > input {
  width: 125px;
  border: 0;
  background: 0;
  margin: 0rem 0.3rem;
  border-bottom: 1px solid gray;
}
.filters {
  margin: 2rem 0rem;
}

h2 {
  font-size: 1rem;
  color: var(--txt-med);
  margin-bottom: 2rem;
  cursor: default;
}
.main-view {
  display: grid;
  grid-template: 'siderbar main';
  grid-template-columns: 1fr minmax(600px, 770px);
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
  z-index: 100;
}
div > .main {
  max-width: 1000px;
  margin: auto;
  padding: 2rem;
  box-sizing: border-box;
  line-height: 1.6;
  color: var(--txt);
  padding: 1.5rem 1.5rem 80vh 1.5rem;
  width: 100%;
  z-index: 100;
}
</style>
<style scoped lang="scss">
.txt h1 {
  font-size: 3rem;
  margin: 0.2rem 0;
  line-height: 1.3;
  max-width: 600px;
}
.main-view {
  transition: background 0.3s;
  section {
    transition: opacity 0.3s;
  }
}

.main-view,
.main-view {
  .main .holder,
  .sider {
    transition: background 0.3s;
  }
}
.hidden {
  .xx {
    a {
      transition: min-width 0.3s;
      min-width: 0px;
      width: fit-content;
    }

    .title {
      opacity: 0;
    }
    .listit {
      border: 0;
      color: black;
    }
    .listit:nth-child(2) {
      background: rgb(220, 20, 60);
    }
    .listit:nth-child(3) {
      background: 	rgb(255, 49, 49);
    }
    .listit:nth-child(4) {
      background: rgb(255, 36, 0);
    }
    .listit:nth-child(5) {
      background: rgb(128, 0, 32);
    }
    .listit:nth-child(6) {
      background: rgb(170, 74, 68);
    }
    .listit:nth-child(7) {
      background: rgb(128, 0, 32);
    }
    .listit:hover {
      background: #fbfbfb;
    }
  }
  .contact,
  section {
    opacity: 0;
  }
  .main,
  .holder,
  .sider {
    background: transparent !important;
  }
  background: transparent !important;
  box-shadow: unset !important;
}
#intro {
  opacity: 100;
}

</style>
